<template>
  <div class="homepage-wrapper">
    <!--        <div id="preloader"></div>-->
    <Header/>
    <!-- header end -->
    <!-- Start Slider Area -->
    <div class="slide-area slide-area-2 fix" data-stellar-background-ratio="0.6">
      <div class="display-table">
        <div class="display-table-cell">
          <div class="container">
            <div class="row">
              <div class="slide-text-inner">
                <div class="col-md-5 col-sm-12 col-xs-12">
                  <!--                                                                        <carousel :scrollPerPage="true" :perPageCustom="[[300,1]]">-->
                  <!--                                                                            <slide>-->
                  <!--                                                                                <div class="slide-content">-->
                  <!--                                                                                    <h1 class="title2">Transfer crypto to your bank account</h1>-->
                  <!--                                                                                    <p class="text-white mt-3 mb-3">Cash out cryptocurrency to local currency instantly to any bank account, anytime and anywhere.</p>-->
                  <!--                                                                                    <div class="layer-1-3">-->
                  <!--                                                                                        <router-link to="/account/register"-->
                  <!--                                                                                                     class="ready-btn left-btn gtm-get-started">Get started-->
                  <!--                                                                                        </router-link>-->
                  <!--                                                                                    </div>-->
                  <!--                                                                                </div>-->
                  <!--                                                                            </slide>-->
                  <!--                                                                            <slide>-->
                  <!--                                                                                <div class="slide-content">-->
                  <!--                                                                                    <h1 class="title2">Send money abroad with cryptocurrency</h1>-->
                  <!--                                                                                    <p class="text-white">The quickest and easiest way to-->
                  <!--                                                                                        transfer money using cryptocurrency-->
                  <!--                                                                                        to your loved ones or yourself.</p>-->
                  <!--                                                                                    <div class="layer-1-3">-->
                  <!--                                                                                        <router-link to="/account/register"-->
                  <!--                                                                                                     class="ready-btn left-btn gtm-get-started">Get started-->
                  <!--                                                                                        </router-link>-->
                  <!--                                                                                    </div>-->
                  <!--                                                                                </div>-->
                  <!--                                                                            </slide>-->
                  <!--                                                                        </carousel>-->
                  <!-- Start Slider content -->
                  <div class="slide-content">
                    <h1 class="title2">Cash out crypto to local currency</h1>
                    <p class="text-white">Transfer money with crypto to any bank account without
                      having to go through an exchange.</p>
                    <div class="layer-1-3">
                      <a @click="openVideoPopup()"
                         class="ready-btn left-btn gtm-watch-video">How It Works
                      </a>
                    </div>
                    <vs-popup title="Cash out your cryptocurrency with TransCrypt"
                              :active.sync="videoPopup">
                      <LazyYoutube :src="videoUrl" ref="transcrypt-video"/>
                      <vs-row class="mt-3" vs-type="flex" vs-justify="flex-end">
                        <button class="btn btn-success" @click="closeVideoPopup()">Close
                        </button>
                      </vs-row>
                    </vs-popup>
                  </div>
                  <!-- End Slider content -->
                </div>
                <div class="col-md-7 col-sm-12 col-xs-12 mb-5">
                  <div class="money-send">
                    <div class="calculator-inner">
                      <div class="single-cal">
                        <transcrypt-transfer-preparation class="trfContainer"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->
    <!-- Start About Area -->
    <div class="about-area bg-color fix area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline text-center">
              <h3>Why cash out crypto with TransCrypt ?</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="support-all">
            <div class="row">
              <!-- Start services -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="support-services ">
                  <a class="support-images"><img
                      src="/assets/remittance-assets/send-money-icon.png" alt=""></a>
                  <div class="support-content">
                    <h4>Cash out crypto faster</h4>
                    <!--                                        <p>Speed up money transfer with cryptocurrency with TransCrypt.-->
                    <!--                                            You don't have to cash out your cryptocurrency with an-->
                    <!--                                            exchange before sending money.-->
                    <!--                                        </p>-->
                    <p>Quick and easy, cash out your cryptocurrency seamlessly with TransCrypt. Skip
                      the cumbersome processes you faced with other crypto exchanges.</p>
                  </div>
                </div>
              </div>
              <!-- Start services -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="support-services ">
                  <a class="support-images"><img
                      src="/assets/remittance-assets/quick-sign-up-icon.png" alt=""></a>
                  <div class="support-content">
                    <h4>Quick sign-up</h4>
                    <p>Sign up, verify your account and start cashing out in minutes.
                      Designed with customer experience in mind, TransCrypt makes cashing out
                      crypto a breeze.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Start services -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="support-services">
                  <a class="support-images"><img
                      src="/assets/remittance-assets/identity-check-icon.png" alt=""></a>
                  <div class="support-content">
                    <h4>Fast & seamless identity check</h4>
                    <p>All you need is yourself and an identity document. Complete a
                      one-time identity verification and
                      get started with cashing out right away.
                    </p>
                  </div>
                </div>
              </div>
              <!-- Start services -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="support-services ">
                  <a class="support-images"><img
                      src="/assets/remittance-assets/low-transfer-fee-icon.png" alt=""></a>
                  <div class="support-content">
                    <h4>Low transfer fee</h4>
                    <p>Enjoy one of the lowest transaction fees you can ever find.
                      Our exchange rates are highly competitive;
                      all rates are clearly shown with no other hidden fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End About Area -->
    <!-- Start How to area -->
    <div class="how-to-area how-to-area-2 bg-color area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline text-center mb-4">
              <h3>How to cash out cryptocurrency with TransCrypt?</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="all-services">
            <!-- single-well end-->
            <div class="col-md-3 col-sm-6 col-xs-12 col-lg-4">
              <div class="well-services first-item wow fadeInUp">
                <div class="well-img">
                  <a class="big-icon support-images"><img
                      src="/assets/remittance-assets/step1.png" alt=""></a>
                </div>
                <div class="main-wel">
                  <div class="wel-content">
                    <h4>1. Enter an amount</h4>
                    <p>Fill in the transfer details. Crypto
                      amounts and fees will be shown
                      upfront. </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- single-well end-->
            <div class="col-md-3 col-sm-6 col-xs-12 col-lg-4">
              <div class="well-services wow fadeInUp">
                <div class="well-img">
                  <a class="big-icon support-images"><img
                      src="/assets/remittance-assets/step2.png" alt=""></a>
                </div>
                <div class="main-wel">
                  <div class="wel-content">
                    <h4>2. Add your details</h4>
                    <p>Add your personal and bank
                      account details.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- single-well end-->
            <div class="col-md-3 col-sm-6 col-xs-12 col-lg-4">
              <div class="well-services thired-item wow fadeInUp">
                <div class="well-img">
                  <a class="big-icon support-images"><img
                      src="/assets/remittance-assets/step3.png" alt=""></a>
                </div>
                <div class="main-wel">
                  <div class="wel-content">
                    <h4>3. Verify your identity</h4>
                    <p>Get ready your identity document for
                      verification. This helps to keep your
                      transaction safe and secure.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- single-well end-->
            <!-- single-well end-->
            <div class="col-md-3 col-sm-6 col-xs-12 col-lg-4 col-lg-offset-2">
              <div class="well-services first-item wow fadeInUp">
                <div class="well-img">
                  <a class="big-icon support-images"><img
                      src="/assets/remittance-assets/step4.png" alt=""></a>
                </div>
                <div class="main-wel">
                  <div class="wel-content">
                    <h4>4. Make payment</h4>
                    <p>Open your crypto wallet, scan the QR
                      code displayed to make payment.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- single-well end-->
            <div class="col-md-3 col-sm-6 col-xs-12 col-lg-4">
              <div class="well-services wow fadeInUp">
                <div class="well-img">
                  <a class="big-icon support-images"><img
                      src="/assets/remittance-assets/step5.png" alt=""></a>
                </div>
                <div class="main-wel">
                  <div class="wel-content">
                    <h4>5. Cash out completed
                    </h4>
                    <p>Receive money in the currency you selected. Track the status in your
                      account.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- single-well end-->
          </div>
        </div>
        <!--                <div class="row">-->
        <!--                    <div class="all-services">-->
        <!--                       -->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </div>
    <!-- End How to area -->
    <!-- Start brand Banner area -->
    <div class="brand-area area-padding fix" data-stellar-background-ratio="0.6">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="brand-text text-center">
              <h3>Ready to cash out your cryptocurrency with TransCrypt?</h3>
              <br>
              <br>
              <router-link class="hire-btn gtm-get-started" to="/account/register">Get started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End brand Banner area -->

    <!-- Start testimonials Area -->
    <div class="reviews-area bg-color fix area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline text-center mb-4">
              <h3>What our customers tell us</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <carousel :scrollPerPage="true" :perPageCustom="[[300,1],[600,2],[768,2],[1000,3]]"
                      class="testi-carousel">
              <slide v-for="item in testimonials" :key="item.name">
                <div class="single-testi">
                  <div class="testi-text">
                    <div class="clients-text">
                      <div class="well-img text-center">
                        <a class="big-icon support-images"><img
                            :src="item.image" alt=""></a>
                      </div>
                      <p class="mt-2 mb-2 text-primary">“{{ item.text }}”</p>
                      <p class="text-center mt-1">
                        <strong>{{ item.name }}, {{ item.country }}</strong>
                      </p>
                    </div>

                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!-- End testimonials end -->

    <!--Association & regulation-->
    <div class="area-padding fix text-center" data-stellar-background-ratio="0.6">
      <div class="container">
        <!--                <div class="row">-->
        <!--                    <div class="col-md-6 col-sm-6 col-xs-12">-->
        <!--                        <div class="row">-->
        <!--                            <div class="col-md-4 col-sm-4 col-xs-12">-->
        <!--                                <p class="text-md-left">In association with</p>-->
        <!--                            </div>-->
        <!--                            <div class="col-md-8 col-sm-8 col-xs-12 mb-4 text-left text-xs-center ">-->
        <!--                                <img src="/assets/remittance-assets/fatf-logo-w.png" alt="">-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="col-md-6 col-sm-6 col-xs-12">-->
        <!--                        <div class="row">-->
        <!--                            <div class="col-md-4 col-sm-4 col-xs-12">-->
        <!--                                <p class="text-md-left">In partnership with</p>-->
        <!--                            </div>-->
        <!--                            <div class="col-md-8 col-sm-8 col-xs-12 mb-4">-->
        <!--                                &lt;!&ndash;                                <img src="/assets/remittance-assets/jp-morgan-chase-gray-w.png" alt="">&ndash;&gt;-->
        <!--                                <img src="/assets/remittance-assets/signature-bank-logo.png" class="mr-3" alt="">-->
        <!--                                <img src="/assets/remittance-assets/silvergate-logo.png" alt="">-->
        <!--                            </div>-->
        <!--                        </div>-->


        <!--                    </div>-->
        <!--                </div>-->
        <div class="row">
          <div style="display: flex;justify-content: center;align-items: center">
            <p class="mr-2">In association with</p>
            <img src="/assets/remittance-assets/fatf-logo-w.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- Start Footer Area -->
    <Footer/>
    <!-- End Footer Area -->
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import {Carousel, Slide} from 'vue-carousel';

import TranscryptTransferPreparation from "./SiteTransferPreparation";
import Footer from "../layouts/HomepageFooter"
import Header from "../layouts/HomepageHeader"

import {LazyYoutube} from "vue-lazytube";


export default {
  name: 'homepage',
  components: {
    TranscryptTransferPreparation,
    Carousel,
    Slide,
    Footer,
    Header,
    LazyYoutube
  },
  data() {
    return {
      testimonials: [
        // {
        //   name: "Adaego",
        //   country: "Nigeria",
        //   text: "With TransCrypt, I can finally send money home to my parents even without a bank account.",
        //   image: "/assets/remittance-assets/adaego-1.png"
        // },
        {
          name: "Andy",
          country: "Belgium",
          text: "It's easy signing up and verifying my account on TransCrypt. I can also cash out my crypto conveniently.",
          image: "/assets/remittance-assets/andy-2.png"
        },
        {
          name: "Antonio",
          country: "Indonesia",
          text: "What I like about TransCrypt is that all fees are shown upfront. Sending money is relatively cheaper too.",
          image: "/assets/remittance-assets/antonio-1.png"
        },
        {
          name: "Oluwadare",
          country: "Nigeria",
          text: "TransCrypt has been a game changer as it enables me to turn my Bitcoin into real cash.",
          image: "/assets/remittance-assets/customer-icon.png"
        },
        {
          name: "Daniel",
          country: "Philippines",
          text: "My first experience with TransCrypt is good and I'm very satisfied with their service.",
          image: "/assets/remittance-assets/customer-icon.png"
        },
        {
          name: "Salman",
          country: "Saudi Arabia",
          text: "Transferred using TransCrypt and received my FIAT in my Saudi Arabia account.",
          image: "/assets/remittance-assets/customer-icon.png"
        },
      ],

      videoPopup: false,
      debugConsole: false,
      videoUrl: "https://www.youtube.com/watch?v=pp5CjmkqeKk",
      urlPathName: "",
    }
  },
  metaInfo: {
    title: 'TransCrypt | Cash Out Cryptocurrency | Transfer Crypto to Bank',
    meta: [
      {
        name: 'description',
        content: 'Quick and easy, transfer cryptocurrency to any bank account without having to go through an exchange. Multi-currency, anytime and anywhere, 24/7'
      }
    ]
  },
  computed: {
    ...mapGetters(["userDetailsGetter"]),

  },
  watch: {},
  mounted() {
    if (this.debugConsole) console.debug("Homepagemounted");
    require('@/assets/js/main.js');

    let url = new URL(window.location);

    //check if mean container is loaded
    if (this.debugConsole) console.log("Mean container loaded", window.jQuery('.mean-container'))
    if (window.jQuery('.mean-container').length === 0) {
      window.jQuery('nav#dropdown').meanmenu()
    }


    //check the URL query string (query string set from Google or FB)
    let query = this.$route.query;
    if (this.debugConsole) console.log("this.$route.query", query)


    if (Object.keys(query).length > 0) {

      //push to state to record the query string on url . Query String could be "logo", the "source", the cashout/sendmoney amount coming from other referral.
      this.$store.commit("UPDATE_QUERY_STRING", query);
      // console.log("Push QueryString to vuex state");
    } else {
      // console.log("Query String not available");
    }

    // this.urlPathName = url.pathname;
    // console.log("Url Pathname in homepage", this.urlPathName);


  },
  methods: {
    ...mapActions(["submitTransferType"]),
    openVideoPopup() {
      this.videoPopup = true;

      //make the close icon hidden
      let element = document.querySelector('.vs-popup--close');
      element.hidden = true;
    },
    closeVideoPopup() {
      this.$refs["transcrypt-video"]['pauseVideo']()
      this.videoPopup = false;
    }
  },

}

</script>
<style scoped>
ul > li {
  list-style-type: none;
}


</style>
